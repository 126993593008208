import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

const TITLE = 'Not Found';

function NotFoundPage({ location }) {
  return (
    <Layout location={location} title={TITLE}>
      <SEO title={TITLE} url={location.href}/>
      <p>You've hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
